






































import { Component, Vue } from "vue-property-decorator";

import { getNews } from "@/api";

interface INews {
  id: number;
  title: string;
  brief: string;
  imgUrl: string;
  dateTime: string;
}

@Component({
  components: {},
})
export default class News extends Vue {
  currentPage = 1;
  pageSize = 5;
  newsList: Array<INews> = [];
  currentNewsList: Array<INews> = [];

  mounted(): void {
    this.fetchNewsList();
  }

  fetchNewsList(): void {
    for (let i = 1; i < 30; i++) {
      this.newsList.push({
        id: i,
        title: i + "--高光开局 | GSIE 2021开启强“芯”之路",
        brief:
          "2021年恰逢“十四五”开局，全面建设社会主义现代化国家新征程开启之年。科技创新已经成为“两会”热议焦点，半导体产业“缺芯”话题受到了政府部门和代表们空前关注。“十四五”规划强调科技自主，解决卡脖子问题，加速国产芯片发展。",
        imgUrl: "",
        dateTime: "2021-03-12 17:45",
      });
    }

    this.getCurrentList();

    getNews({ pageSize: this.currentPage }).then((res) => {
      this.newsList = res.data;
    });
  }

  handleSizeChange(val: number): void {
    this.pageSize = val;
    this.currentPage = 1;
    this.getCurrentList();
  }

  handleCurrentChange(val: number): void {
    this.currentPage = val;
    this.getCurrentList();
  }

  getCurrentList(): void {
    this.currentNewsList = this.newsList.slice(
      (this.currentPage - 1) * this.pageSize,
      (this.currentPage - 1) * this.pageSize + this.pageSize
    );
  }
}
